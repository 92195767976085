import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../sass/main.scss';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>
    <form
              className="form"
              data-netlify="true"
              name="get-involved-resources"
              method="POST"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="get-involved-resources" />

              <div style={{ display: "none" }}>
                <input name="bot-field" />
              </div>

              <div className="inputs">
                <input type="text" name="first_name" required placeholder="Your first name" />
                <input type="email" required name="email" placeholder="Your email" />
              </div>

              <input
                className="input"
                type="text"
                required
                name="additional"
                placeholder="Additional field"
              />

              <select className="select" name="reason[]" required>
                <option value="">Tell us what you'd like to do</option>
                <option value="story">story</option>
                <option value="podcast">Podcast guest</option>
                <option value="blog">Write a blog post</option>
                <option value="newsletter">Sign up to newsletter</option>
              </select>

              <div className="button">
                <button type="submit">Send</button>
              </div>
            </form>
  </Layout>
)

export default ContactPage
